<template>
  <div id="test8">
    <div class="crumbs">车辆管理 / 车辆列表</div>
    <div class="center">
      <div class="table">
        <div class="center_top">
          <span>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="关键字搜索">
                <el-input
                  placeholder="请输入车牌号或车辆名称"
                  v-model="keywords"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属街镇">
                <el-select v-model="search_street_id" placeholder="请选择">
                  <el-option :label="'全部'" :value="''"> </el-option>
                  <el-option
                    v-for="streetItem in StreetList"
                    :label="streetItem.street_name"
                    :value="streetItem.street_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit" size="medium"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="operateVihicleShow = true"
                  size="medium"
                  >添加车辆</el-button
                >
                <el-button type="primary" @click="asyncDeviceIdfn" size="medium"
                  >同步车辆GPS设备ID</el-button
                >
              </el-form-item>
            </el-form>
          </span>
        </div>

        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
        >
          <el-table-column
            prop="vihicle_id"
            label="编号"
            width="50"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column
            prop="vihicle_type"
            label="车辆类型"
            width="180"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column label="车辆图片" width="100" :align="'center'">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.imgs"
                type="text"
                @click="toimg(scope.row.imgs)"
                >点击查看</el-button
              >
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="vihicle_number"
            label="车牌号"
            width="200"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column
            prop="vihicle_nickname"
            label="车辆昵称"
            width="80"
            :align="'center'"
          >
          </el-table-column>

          <el-table-column label="工作状态" width="180" :align="'center'">
            <template slot-scope="scope">
              <el-radio
                v-model="scope.row.work_status"
                :label="0"
                :disabled="scope.row.work_status === 0 ? false : true"
                >故障</el-radio
              >
              <el-radio
                v-model="scope.row.work_status"
                :label="1"
                :disabled="scope.row.work_status === 1 ? false : true"
                >正常</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column label="连接状态" :align="'center'" width="200">
            <template slot-scope="scope">
              <el-radio
                v-model="scope.row.connect_status"
                :label="0"
                :disabled="scope.row.connect_status === 0 ? false : true"
                >已离线</el-radio
              >
              <el-radio
                v-model="scope.row.connect_status"
                :label="1"
                :disabled="scope.row.connect_status === 1 ? false : true"
                >运行中</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="street_name"
            label="所属街道"
            width="120"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column
            prop="fence_name"
            label="所属围栏"
            width="120"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column
            prop="device_id"
            label="device_id"
            width="120"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column label="操作" :align="'center'">
            <template slot-scope="scope">
              <!-- <el-button
                type="text"
                @click="toToCardReport(scope.row.vihicle_id)"
                >查看报表
              </el-button> -->
              <el-button type="text" @click="editCarMockData(scope.row)"
                >车辆数据
              </el-button>
              <el-button
                type="text"
                @click="archivesSignInShowFn(scope.row.vihicle_id)"
                >档案
              </el-button>
              <el-button
                type="text"
                @click="maintainShowFn(scope.row.vihicle_id)"
                >保养</el-button
              >
              <el-button type="text" @click="repairShowFn(scope.row.vihicle_id)"
                >维修</el-button
              >
              <el-button type="text" @click="LookFiles(scope.row.vihicle_id)"
                >查看档案</el-button
              >
              <el-button type="text" @click="to(scope.row.vihicle_id)"
                >查看保养/维修</el-button
              >
              <el-button type="text" @click="editcatShowFn(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="remove(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <span style="float: right; margin-top: 20px; margin-right: 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="counts"
            background
          >
          </el-pagination>
        </span>
      </div>
    </div>

    <el-dialog
      title="编辑车辆档案"
      :visible.sync="archivesSignInShow"
      width="30%"
    >
      <el-form label-width="130px" :model="archivesSignInForm">
        <el-form-item label="车辆规格:">
          <el-input v-model="archivesSignInForm.specifications"></el-input>
        </el-form-item>
        <el-form-item label="车架号:">
          <el-input v-model="archivesSignInForm.vehicle_number"></el-input>
        </el-form-item>
        <el-form-item label="购置时间:">
          <el-input v-model="archivesSignInForm.buy_time"></el-input>
        </el-form-item>
        <el-form-item label="上次年审时间:">
          <el-date-picker
            v-model="archivesSignInForm.vihicle_next_checked_time"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保险到期时间:">
          <el-input v-model="archivesSignInForm.insure"></el-input>
        </el-form-item>
        <el-form-item label="行驶证/交强险/商业险图片:" prop="staff_phone">
          <el-upload
            :action="'https://zhhw.fenfeneco.com/api/common/userUpload'"
            :on-success="upLoadsuccess"
            :file-list="fileList"
            list-type="picture"
            :headers="headers"
            :on-remove="upLoadremove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="保险公司电话:">
          <el-input v-model="archivesSignInForm.insure_phone"></el-input>
        </el-form-item>
        <el-form-item label="承保保险公司:">
          <el-input v-model="archivesSignInForm.insure_name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="archivesSignInShow = false">取 消</el-button>
        <el-button type="primary" @click="archivesSignInFn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="车辆档案" :visible.sync="LookFilesShow" width="70%">
      <el-table
        :data="LookFilesForm"
        style="width: 100%"
        :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }"
      >
        <el-table-column
          prop="specifications"
          label="规格"
          width="200"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="vehicle_number"
          label="车架号"
          width="200"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column label="行驶证/交强险/商业险图片" :align="'center'">
          <template slot-scope="scope" v-show="scope.row.licence.length > 0">
            <div v-for="item in scope.row.licence" :key="item">
              <img :src="item" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="buy_time"
          label="购置时间"
          width="130"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="insure"
          label="保险到期时间"
          width="130"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="insure_name" label="保险公司" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="insure_phone"
          label="保险公司电话"
          width="130"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="vihicle_next_checked_time"
          label="上次年审时间"
          width="130"
          :align="'center'"
        >
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="LookFilesShow = false">取 消</el-button>
        <el-button type="primary" @click="LookFilesShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="车辆保养登记" :visible.sync="maintainShow" width="30%">
      <el-form label-width="100px" :model="maintainForm">
        <el-form-item label="保养项目:">
          <el-input
            v-model="maintainForm.maintain_log"
            placeholder="例如:两万公里大保养"
          ></el-input>
        </el-form-item>
        <el-form-item label="保养价格:">
          <el-input v-model="maintainForm.maintain_fee"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="maintainShow = false">取 消</el-button>
        <el-button type="primary" @click="maintainFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="车辆维修登记" :visible.sync="repairShow" width="30%">
      <el-form label-width="100px" :model="repairForm">
        <el-form-item label="维修项目:">
          <el-input
            v-model="repairForm.repair_log"
            placeholder="例如:更换轮胎"
          ></el-input>
        </el-form-item>
        <el-form-item label="维修价格:">
          <el-input v-model="repairForm.repair_time"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="repairShow = false">取 消</el-button>
        <el-button type="primary" @click="repairFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="车辆维修登记" :visible.sync="repairShow" width="30%">
      <el-form label-width="100px" :model="repairForm">
        <el-form-item label="维修项目:">
          <el-input
            v-model="repairForm.repair_log"
            placeholder="例如:更换轮胎"
          ></el-input>
        </el-form-item>
        <el-form-item label="维修价格:">
          <el-input v-model="repairForm.repair_time"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="repairShow = false">取 消</el-button>
        <el-button type="primary" @click="repairFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加车辆" :visible.sync="operateVihicleShow" width="30%">
      <el-form label-width="100px" :model="operateVihicleForm">
        <el-form-item label="车辆类型:">
          <el-select
            v-model="operateVihicleForm.vihicle_type"
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionsCarType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆昵称:">
          <el-input v-model="operateVihicleForm.vihicle_nickname"></el-input>
        </el-form-item>
        <el-form-item label="车牌号:">
          <el-input v-model="operateVihicleForm.vihicle_number"></el-input>
        </el-form-item>
        <AreaSelect ref="_Area" street_type="select" :areadata="areaData" />
        <el-form-item label="车辆图片:" prop="staff_phone">
          <el-upload
            :action="'https://zhhw.fenfeneco.com/api/common/userUpload'"
            :on-success="upLoadsuccess"
            :file-list="fileList"
            list-type="picture"
            :headers="headers"
            :on-remove="upLoadremove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="repairShow = false">取 消</el-button>
        <el-button type="primary" @click="operateVihicleFn">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑车辆" :visible.sync="editcatShow" width="30%">
      <el-form label-width="100px" :model="editCatData">
        <el-form-item label="车辆类型:">
          <el-select v-model="editCatData.vihicle_type" placeholder="请选择">
            <el-option
              v-for="item in optionsCarType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆昵称:">
          <el-input v-model="editCatData.vihicle_nickname"></el-input>
        </el-form-item>
        <el-form-item label="车牌号:">
          <el-input v-model="editCatData.vihicle_number"></el-input>
        </el-form-item>
        <AreaSelect ref="_Area" street_type="select" :areadata="areaData" />
        <el-form-item label="车辆图片:" prop="staff_phone">
          <el-upload
            :action="'https://zhhw.fenfeneco.com/api/common/userUpload'"
            :on-success="upLoadsuccess"
            :file-list="fileList"
            list-type="picture"
            :headers="headers"
            :on-remove="upLoadremove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editcatShow = false">取 消</el-button>
        <el-button type="primary" @click="editcatFn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑车辆" :visible.sync="editCarMockDataShow" width="30%">
      <el-form label-width="100px" :model="carMockData">
        <el-form-item label="车辆类型:">
          <el-select v-model="carMockData.isConn" placeholder="请选择">
            <el-option
              v-for="item in optionsCarisConn"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input
                v-model="cc.longitudeDegree"
                placeholder="度"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="cc.longitudeBranch"
                placeholder="分"
              ></el-input>
            </div>
            <div style="flex: 1">
              <el-input
                v-model="cc.longitudeSecond"
                placeholder="秒"
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="纬度:">
          <div style="display: flex">
            <div style="flex: 1">
              <el-input v-model="cc.latitudeDegree" placeholder="度"></el-input>
            </div>
            <div style="flex: 1">
              <el-input v-model="cc.latitudeBranch" placeholder="分"></el-input>
            </div>
            <div style="flex: 1">
              <el-input v-model="cc.latitudeSecond" placeholder="秒"></el-input>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editCarMockDataShow = false">取 消</el-button>
        <el-button type="primary" @click="addCarMockData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getVihicleList,
  archivesSignIn,
  archivesInfo,
  maintenanceSignIn,
  operateVihicle,
  getGrantStreetList,
  asyncDeviceId,
  getVihicleSimulateData,
  addVihicleSimulateData,
} from "../request/api";
import AreaSelect from "../components/AreaSelect/AreaSelect.vue";
export default {
  name: "DemoGetvihiclelist",
  components: {
    AreaSelect,
  },
  data() {
    return {
      headers: {
        token: "",
      },
      search_street_id: "",
      fileList: [],
      tableData: [],
      counts: 0, //总数
      page: 1, //当前页
      limit: 10, //一页展示几条
      keywords: "",
      vihicle_type: "",
      archivesSignInShow: false,
      archivesSignInForm: {
        specifications: "",
        vehicle_number: "",
        buy_time: "",
        insure: "",
        insure_phone: "",
        vihicle_next_checked_time: "",
        insure_name: "",
      },
      id: 0,
      images: [],
      LookFilesShow: false,
      LookFilesForm: [
        {
          specifications: "",
          vehicle_number: "",
          licence: "",
          buy_time: "",
        },
      ],
      maintainShow: false,
      //保养
      maintainForm: {
        maintain_log: "",
        maintain_fee: "",
      },
      //维修
      repairForm: {
        repair_log: "",
        repair_time: "",
      },
      repairShow: false,
      operateVihicleForm: {
        vihicle_type: "",
        vihicle_nickname: "",
        vihicle_number: "",
        street_id: "",
      },
      operateVihicleShow: false,
      optionsCarType: [
        {
          value: "环卫车辆",
        },
        {
          value: "电动三轮车",
        },
      ],
      optionsCarisConn: [
        {
          key: "1",
          value: "在线",
        },
        {
          key: "0",
          value: "离线",
        },
      ],
      StreetList: null,
      editcatShow: false,
      editCatData: {},
      areaData: {
        province: "",
        city: "",
        area: "",
        street: "",
        street_id: null,
      },
      editCarMockDataShow: false,
      carMockData: {
        isConn: "1",
        lat: "",
        lng: "",
        vihicle_id: 0,
      },
      cc: {
        longitudeDegree: "", // 经度
        longitudeBranch: "", // 经分
        longitudeSecond: "", // 经秒

        latitudeDegree: "", // 纬度
        latitudeBranch: "", // 纬分
        latitudeSecond: "", // 纬秒
      },
    };
  },
  async mounted() {
    this.getList();
    this.headers.token = sessionStorage.getItem("token");
    let res1 = await getGrantStreetList();
    this.StreetList = res1.data.list;
  },
  methods: {
    //度分秒转经纬度
    degreeMinuteSecondConvertCoordinates(degree, minute, second) {
      return (
        Number(degree) +
        Number(minute / 60) +
        Number(second / 60 / 60)
      ).toFixed(8);
    },

    convertDegreesToDMS(degrees) {
      const d = Math.floor(degrees); // 取整得到度
      const md = Math.abs(degrees - d) * 60; // 剩余的分钟部分
      const m = Math.floor(md); // 取整得到分
      const s = ((md - m) * 60).toFixed(4); // 剩余的秒部分，四舍五入

      // 根据度数的正负决定方向（N/S 或 E/W）
      let direction = "";
      if (degrees < 0) {
        direction = "S"; // 假设这是纬度（南纬）
      } else {
        direction = "N"; // 假设这是纬度（北纬）
      }
      // 如果是经度，则替换为 'E' 或 'W'

      // 格式化输出
      // return `${d}°${m}'${s}" ${direction}`;
      return {
        d,
        m,
        s,
      };
    },
    async editCarMockData(row) {
      console.log("vihicle_id------>", row.vihicle_id);

      const res = await getVihicleSimulateData({
        vihicle_id: row.vihicle_id,
      });

      this.carMockData.vihicle_id = row.vihicle_id;
      if (res.code == 0 || res.msg == "暂无数据") {
        this.carMockData.isConn = 1;
        this.cc.latitudeDegree = 0;
        this.cc.latitudeBranch = 0;
        this.cc.latitudeSecond = 0;

        this.cc.longitudeDegree = 0;
        this.cc.longitudeBranch = 0;
        this.cc.longitudeSecond = 0;
        this.editCarMockDataShow = true;
        return;
      }

      this.carMockData.isConn = res.data.isConn;

      if (res.data.lat && res.data.lng) {
        const la = this.convertDegreesToDMS(res.data.lat);
        const lo = this.convertDegreesToDMS(res.data.lng);

        this.cc.latitudeDegree = la.d;
        this.cc.latitudeBranch = la.m;
        this.cc.latitudeSecond = la.s;

        this.cc.longitudeDegree = lo.d;
        this.cc.longitudeBranch = lo.m;
        this.cc.longitudeSecond = lo.s;

        console.log("lalalala", la);
        console.log("lolololo", lo);
      }

      this.editCarMockDataShow = true;
    },
    async addCarMockData() {
      const lo = this.degreeMinuteSecondConvertCoordinates(
        this.cc.longitudeDegree,
        this.cc.longitudeBranch,
        this.cc.longitudeSecond
      );
      const la = this.degreeMinuteSecondConvertCoordinates(
        this.cc.latitudeDegree,
        this.cc.latitudeBranch,
        this.cc.latitudeSecond
      );

      this.carMockData.lat = la;
      this.carMockData.lng = lo;

      const res = await addVihicleSimulateData({
        ...this.carMockData,
      });

      if (res.code == 1 || res.msg == "更新成功") {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.editCarMockDataShow = false;
      } else {
        this.editCarMockDataShow = false;
        this.$message({
          message: "更新失败",
          type: "error",
        });
      }

      console.log(res);
      // console.log(lo);
      // console.log(la);
    },
    async asyncDeviceIdfn() {
      let res = await asyncDeviceId();
      if (res.code == 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      }
    },
    onSubmit() {
      this.page > 1 && (this.page = 1);

      this.getList();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    async getList() {
      let res = await getVihicleList({
        keywords: this.keywords,
        street_id: this.search_street_id,
        page: this.page,
        limit: this.limit,
      });
      this.tableData = res.data.list;
      this.counts = res.data.counts;
    },
    archivesSignInShowFn(id) {
      this.id = id;
      this.archivesSignInShow = true;
    },
    toToCardReport(vihicle_id) {
      console.log("vihicle_id", vihicle_id);
      this.$router.replace({
        path: "/cardReport",
        query: {
          car_id: vihicle_id,
        },
      });
    },

    //提交
    async archivesSignInFn() {
      let res = await archivesSignIn({
        vihicle_id: this.id,
        specifications: this.archivesSignInForm.specifications,
        vehicle_number: this.archivesSignInForm.vehicle_number,
        buy_time: this.archivesSignInForm.buy_time,
        licence: this.images.join(","),
        insure: this.archivesSignInForm.insure,
        vihicle_next_checked_time:
          this.archivesSignInForm.vihicle_next_checked_time,
        insure_phone: this.archivesSignInForm.insure_phone,
        insure_name: this.archivesSignInForm.insure_name,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.images = [];
        this.fileList = [];
        this.archivesSignInShow = false;
        this.getList();
      } else {
        this.$message.error("操作失败，请检查是否有项目未填写");
      }
    },
    //上传成功的钩子
    upLoadsuccess(response, file, fileList) {
      this.images.push(response.data.oss_url);
    },
    //删除已上传图片的钩子
    upLoadremove(file, fileList) {
      this.images = [];
      fileList.forEach((item) => {
        this.images.push(item.response.data.oss_url);
      });
    },
    async LookFiles(id) {
      let res = await archivesInfo({
        vihicle_id: id,
        type: 1,
      });
      this.LookFilesShow = true;
      if (res.data.info != null) {
        let obj = res.data.info;
        if (obj.licence) {
          obj.licence = obj.licence.split(",");
        }
        this.LookFilesForm = [obj];
      } else {
        this.LookFilesForm = [];
      }
    },
    maintainShowFn(id) {
      this.id = id;
      this.maintainShow = true;
    },
    //保养登记
    async maintainFn() {
      let res = await maintenanceSignIn({
        vihicle_id: this.id,
        type: 2,
        repair_log: this.maintainForm.maintain_log,
        repair_time: this.maintainForm.maintain_fee,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.maintainShow = false;
        this.maintainForm = {
          maintain_log: "",
          maintain_fee: "",
        };
      }
    },
    repairShowFn(id) {
      this.id = id;
      this.repairShow = true;
    },
    //维修登记
    async repairFn() {
      let res = await maintenanceSignIn({
        vihicle_id: this.id,
        type: 1,
        maintain_log: this.repairForm.repair_log,
        maintain_fee: this.repairForm.repair_time,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.repairShow = false;
        this.repairForm = {
          repair_log: "",
          repair_time: "",
        };
      }
    },
    async operateVihicleFn() {
      const _street_id = this.$refs._Area.areaIds.street_id;

      if (_street_id == "" || _street_id == null || _street_id == undefined) {
        this.$message.error("请先选择区域!");
        return;
      }

      this.operateVihicleForm.street_id = _street_id;

      let res = await operateVihicle({
        ...this.operateVihicleForm,
        imgs: this.images.join(","),
      });
      if (res.code == 1) {
        this.getList();
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.operateVihicleShow = false;
        this.images = [];
        this.fileList = [];
        this.operateVihicleForm = {
          vihicle_type: "",
          vihicle_nickname: "",
          vihicle_number: "",
          street_id: "",
        };
      }
    },
    remove(row) {
      this.$confirm("确认删除该车辆？")
        .then(async () => {
          let res = await operateVihicle({
            vihicle_id: row.vihicle_id,
            is_delete: 1,
            street_id: row.street_id,
            vihicle_type: row.vihicle_type,
          });
          if (res.code == 1) {
            this.getList();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    to(id) {
      this.$router.push({
        path: "/archivesInfo",
        query: {
          id,
        },
      });
    },
    editcatShowFn(data) {
      this.areaData.province = data.info.province_name;
      this.areaData.city = data.info.city_name;
      this.areaData.area = data.info.area_name;
      this.areaData.street = data.street_name;
      this.areaData.street_id = data.street_id;

      this.editCatData = data;
      this.editcatShow = true;
      // this.id = data.vihicle_id
    },
    async editcatFn() {
      let _street_id = this.$refs._Area.areaIds.street_id;

      if (isNaN(_street_id)) {
        _street_id = this.areaData.street_id;
      }

      if (_street_id == "" || _street_id == null || _street_id == undefined) {
        this.$message.error("请先选择区域!");
        return;
      }

      this.editCatData.street_id = _street_id;

      await operateVihicle({
        vihicle_type: this.editCatData.vihicle_type,
        vihicle_nickname: this.editCatData.vihicle_nickname,
        vihicle_number: this.editCatData.vihicle_number,
        street_id: this.editCatData.street_id,
        vihicle_id: this.editCatData.vihicle_id,
        imgs: this.images.join(","),
      });
      this.$message({
        message: "操作成功",
        type: "success",
      });
      this.images = [];
      this.fileList = [];
      this.editcatShow = false;
      this.getList();
    },
    toimg(img) {
      this.$router.push({
        path: "/img",
        query: {
          img,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
#test8 {
  width: 100%;
  height: 100vh;
}

.crumbs {
  padding: 5px 20px;
  background-color: rgb(240, 240, 240);
}

.center {
  width: 100vw;
  height: calc(100% - 31px);
  margin: auto;

  .center_top {
    padding-top: 10px;
    padding-bottom: 13px;
    height: 36px;
    display: flex;
    justify-content: center;

    ::v-deep .el-input__inner {
      width: 190px;
      height: 32px;
    }
  }

  .table {
    height: calc(100vh - 100px);
    overflow: auto;
    padding: 0 10px;
  }
}
</style>
